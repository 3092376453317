import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';
import { longDateTime, shortDateAndYear } from '../../../../../../../shared/utils/formattedDates';

import { AddToCalendarLinks } from './components/AddToCalendarLinks';
import { ContactCard } from './components/ContactCard';

import { Card, Button } from 'hosman-material';
import { S_Check } from 'hosman-material';

const NextVisit = ({ visit, visits }) => {
  const { salesInterests } = useSelector((state) => state.buyerSalesInterests);
  const sale = salesInterests.find(sale => sale.id === visit.sale_id);
  const visitIsDone = visit.state == 'done';

  if (!sale) return null;
  return (
    <div className='flex flex-col gap-3' data-testid='NextVisit'>
      <div className='flex items-center gap-3'>
        <p className='h3 text-ih-indigo'>Agenda des visites</p>
        {visits.length > 1 &&
          <Link to={salesInterests.length > 1 ? '/ea/interests' : `/ea/interests/${sale.id}/mes-visites`} className='text-ih-blue underline'>+{visits.length-1} autre{visits.length-1 > 1 ? 's' : ''}</Link>
        }
      </div>

      <Card classes='flex flex-col md:flex-row justify-between gap-3 p-4 border-ih-purple-20'>
        <div className='flex flex-col md:flex-row gap-5'>
          <img className='w-full md:w-24 aspect-[3/1] md:aspect-[1/2] object-cover rounded-2xl' src={sale.property.property_pictures[0]?.photo_url} />

          <div className='flex flex-col justify-between gap-3'>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-wrap justify-center md:justify-start items-center gap-2'>
                <FontAwesomeIcon icon={S_Check} className='text-ih-green' />
                {!visitIsDone && <p className='font-semibold'>Visite prévue {longDateTime({ date: visit.start_time })}</p>}
                {!visitIsDone && <AddToCalendarLinks visit={visit} />}
                {visitIsDone && <p className='font-semibold'>Suite à votre visite du {shortDateAndYear(visit.start_time)}, des informations liées au bien sont disponibles</p>}
              </div>

              <div className='flex flex-wrap justify-center md:justify-start items-center gap-2'>
                <p className='text-ih-indigo-40'>{sale.property.address}</p>
                <a href={sale.property.google_maps_link} target='_blank' className='text-ih-blue underline font-semibold'>Visualiser l'adresse</a>
              </div>

            </div>

            <div className='flex gap-3'>
              {sale.expert &&
                <ContactCard
                  imageSrc={sale.expert.photo}
                  title='Expert Hosman'
                  firstName={sale.expert.first_name}
                  phoneNumber={sale.expert.phone_number}
                  email={sale.expert.email}
                  civility={sale.expert.civility} />
              }

              {visit.visit_guide &&
                <ContactCard
                  imageSrc={visit.visit_guide.profile_pic_url}
                  title='Guide de visite'
                  firstName={`${visit.visit_guide.user.first_name} ${visit.visit_guide.user.last_name}`}
                  phoneNumber={visit.visit_guide.user.phone_number} />
              }
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-around'>
          <Link to={visitIsDone ? generateLink(RailsRoutes.post_visit_adverts_property_path(sale.property.slug)) : generateLink(RailsRoutes.adverts_property_path(sale.property.slug))} target='_blank'>
            <Button label={visitIsDone ? "Voir l'annonce enrichie" : "Voir l'annonce"} />
          </Link>
        </div>
      </Card>
    </div>
  )
};

export default NextVisit;
