import client from '../../client';

const BUYER_ALERTS = {
  getBuyerAlerts() {
    return client.get('/me/buyers/buyer_alerts');
  },

  createBuyerAlert(params) {
    return client.post(`/me/buyers/buyer_alerts`, { buyer_alert: params });
  },

  updateBuyerAlert(buyerAlertId, params) {
    const { id, ...buyerAlertWithoutId } = params;
    return client.put(`/me/buyers/buyer_alerts/${buyerAlertId}`, { buyer_alert: buyerAlertWithoutId });
  },
}

export default BUYER_ALERTS;
