import React from 'react';
import { useSelector } from 'react-redux';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';

import PropertySummary from '../../../../../../components/Property/PropertySummary'
import SaleStateLabel from '../../../../../../components/Sale/StateLabel';

import { Label, PriceBox, ContactCard, Button } from 'hosman-material';

const SalePropertySummary = () => {
  const { sale } = useSelector((state) => state.sellerSales);

  return (
    <div className='flex flex-col items-center gap-10 md:w-4/5 mx-auto'>
      <div className='text-center text-2xl font-bold'>
        {sale.state === 'awaiting_offer' ?
          <><span className='text-ih-blue'>Visibilité</span> de votre annonce</>
          :
          'Votre annonce'
        }
      </div>

      <div className='flex flex-col md:flex-row justify-center items-center gap-6'>
        <PropertySummary
          property={sale.property}
          link={generateLink(RailsRoutes.adverts_property_path(sale.property.slug))}
          stateLabel={sale && <SaleStateLabel sale={sale} />}
          cityLabel={sale && <Label color='bg-ih-indigo'>{sale.property.city}</Label>}
          avatar={sale.expert?.user?.profile_pic_url}
          propertyInfos
          premium={sale.premium}
        >
          <div className='w-1/2 md:w-max mx-auto md:mx-0 mb-3 md:mb-0'>
            <PriceBox title='Prix de vente' price={sale.price.toLocaleString()} />
          </div>

          <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 mb-3 md:mb-0'>
            {!sale.property.advert?.draft &&
              <Button label="Voir l'annonce" small />
            }

            {sale.property.advert?.link_virtual_visit &&
              <a href={sale.property.advert.link_virtual_visit} target='_blank' onClick={(e) => e.stopPropagation()}>
                <Button label='Visite 3D' type='tertiary' small />
              </a>
            }
          </div>
        </PropertySummary>

        {sale.visit_guide &&
          <ContactCard
            avatar={sale.visit_guide.profile_pic_url}
            avatarSize='w-20 h-20'
            name={`${sale.visit_guide.user.first_name} ${sale.visit_guide.user.last_name}`}
            phone={sale.visit_guide.user.phone_number}
            title='visit_guide'
          />
        }
      </div>
    </div>
  )
}

export default SalePropertySummary;
