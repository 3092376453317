import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { D_House, D_User, S_Eye, S_EyeSlash } from 'hosman-material';
import { FadeIn, Card, RadioInput, TextInput, PhoneNumberInput, InfoIcon, Button } from 'hosman-material';

import Sidebar from '../../../components/Sidebar/Sidebar';
import GeocoderInput from '../../../../shared/components/GeocoderInput';
import PageTitle from '../../../components/PageTitle/PageTitle';

import USER from '../../../api/ressources/App/user';
import { getUser } from '../../../reducers/App/userSlice';

import userKind from '../../../../shared/utils/userKind';

const navigation = [
  { name: "Retour à l'espace client", path: '/', icon: D_House }
]

export const MyAccount = ({ accountCard }) => {
  const { user } = useSelector((state) => state.user);
  const [userState, setUserState] = useState({});
  const [showPasswords, setShowPasswords] = useState({ password: false, new_password: false , new_password_confirmation: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    setUserState({
      civility: user.civility,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      phone_country_code: user.phone_country_code,
      current_password: '',
      password: '',
      password_confirmation: '',
      physical_address_attributes: {
        address: user.physical_address?.address,
        additional_address: user.physical_address?.additional_address
      }
    })
  }, [user]);

  const handleNavChange = (navigation) => {
    navigate(navigation.path);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setUserState((prev) => {
      return {...userState, [name]: value};
    });
  };

  const handleAddressChange = (addressObj) => {
    setUserState((prev) => {
      return {...userState,
        physical_address_attributes: {
          ...addressObj,
          additional_address: prev.physical_address_attributes?.additional_address
        }
      };
    });
  };

  const submitForm = () => {
    setFormErrors({});
    setIsSubmitting(true);

    const { email, ...userStateWithoutEmail } = userState
    USER.updateUser(userStateWithoutEmail)
      .then(function (response) {
        if (userStateWithoutEmail.password) {
          toast.success('Votre mot de passe a été modifié, vous allez être redirigé vers la page de connexion', { duration: 5000 })
          setTimeout(() => window.location.reload(), 3000)
        } else {
          toast.success('Les informations ont bien été enregistrées', { duration: 5000 })
          dispatch(getUser());
          setIsSubmitting(false);
        }
      })
      .catch(function (error) {
        setFormErrors(error.response.data?.error?.message);
        setIsSubmitting(false);
      });
  };

  return (
    <div data-testid='MyAccount'>
      <Sidebar
        navigation={navigation}
        accountCard={accountCard}
        handleSelect={handleNavChange}
      >
        <PageTitle icon={D_User} title='Mon compte' />
        <FadeIn>
          <div className='md:w-2/3 mx-auto flex flex-col gap-10'>
            <Card>
              <div className='p-5'>
                <p className='h3 text-ih-indigo mb-3'>Informations personnelles</p>
                <div className='grid md:grid-cols-2 gap-5'>
                  <div className='md:col-span-2'>
                    <RadioInput
                      label='État civil :'
                      name='civility'
                      options={[
                        { name: 'Monsieur', value: 'mr' },
                        { name: 'Madame', value: 'mrs' }
                      ]}
                      value={userState.civility}
                      onChange={(val) => handleInputChange({ target: { name: 'civility', value: val }})}
                    />
                  </div>
                  <TextInput label='Prénom' name='first_name' value={userState.first_name} onChange={handleInputChange} errors={formErrors} />
                  <TextInput label='Nom' name='last_name' value={userState.last_name} onChange={handleInputChange} errors={formErrors} />
                  <TextInput label='E-mail' name='email' value={userState.email} disabled />
                  <PhoneNumberInput
                    onChangePhone={(val) => handleInputChange({ target: { name: 'phone_number', value: val }})}
                    onChangeCountryCode={(val) => handleInputChange({ target: { name: 'phone_country_code', value: val }})}
                    phoneValue={userState.phone_number}
                    countryCodeValue={userState.phone_country_code}
                    errors={formErrors}
                  />
                </div>
              </div>
            </Card>
            <Card>
              <div className='p-5'>
                <p className='h3 text-ih-indigo mb-3'>Adresse postale</p>
                <div className='grid gap-5'>
                  <GeocoderInput name='address' value={userState.physical_address_attributes?.address} onChange={handleAddressChange} errors={formErrors}/>

                  <TextInput
                    label="Complément d'adresse"
                    name='additional_address'
                    value={userState.physical_address_attributes?.additional_address || ''}
                    onChange={(e) =>
                      setUserState((prev) => {
                        return {...userState, physical_address_attributes: { additional_address: e.target.value} };
                      })
                    }
                  />
                </div>
              </div>
            </Card>
            <Card>
              <div className='p-5'>
                <p className='h3 text-ih-indigo mb-3'>Changement de mot de passe</p>
                <div className='grid md:grid-cols-2 gap-5'>
                  <div className='md:col-span-2 flex flex-col md:flex-row-reverse items-center gap-4'>
                    <div className='flex items-center gap-3 w-full md:mt-8'>
                      <InfoIcon />
                      <p className='text-ih-neutral text-sm'>Afin de changer votre mot de passe veuillez saisir votre mot de passe actuel</p>
                    </div>

                    <div className='relative w-full'>
                      <TextInput
                        type={showPasswords.password ? 'text' : 'password'}
                        label='Mot de passe actuel'
                        name='current_password'
                        value={userState.current_password}
                        onChange={handleInputChange} errors={formErrors}
                      />

                      <FontAwesomeIcon icon={showPasswords.password ? S_EyeSlash : S_Eye} className='text-ih-purple-50 absolute top-12 right-2 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, password: !showPasswords.password })} />
                    </div>
                  </div>

                  <div className='relative w-full'>
                    <TextInput
                      type={showPasswords.new_password ? 'text' : 'password'}
                      label='Nouveau mot de passe'
                      name='password'
                      value={userState.password}
                      onChange={handleInputChange} errors={formErrors}
                    />

                    <FontAwesomeIcon icon={showPasswords.new_password ? S_EyeSlash : S_Eye} className='text-ih-purple-50 absolute top-12 right-2 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, new_password: !showPasswords.new_password })} />
                  </div>

                  <div className='relative w-full'>
                    <TextInput
                      type={showPasswords.new_password_confirmation ? 'text' : 'password'}
                      label='Confirmer le nouveau mot de passe'
                      name='password_confirmation'
                      value={userState.password_confirmation}
                      onChange={handleInputChange} errors={formErrors}
                    />

                    <FontAwesomeIcon icon={showPasswords.new_password_confirmation ? S_Eye : S_EyeSlash} className='text-ih-purple-50 absolute top-12 right-2 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, new_password_confirmation: !showPasswords.new_password_confirmation })} />
                  </div>
                </div>
              </div>
            </Card>

            <div className='md:flex justify-end'>
              <div className='min-w-max'>
                <Button label='Enregistrer les informations' onClick={submitForm} isLoading={isSubmitting} />
              </div>
            </div>
          </div>
        </FadeIn>
      </Sidebar>
    </div>
  )
}

export default MyAccount;
