import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LoadingLayer, FadeIn, Separator } from 'hosman-material';
import { D_Book } from 'hosman-material';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import Articles from './components/Articles';
import Partners from './components/Partners';

import { getArticles } from '../../../../reducers/App/articlesSlice';
import PARTNERS from '../../../../api/ressources/App/partners';

const BuyerGuide = () => {
  const { articles } = useSelector((state) => state.articles);
  const [partners, setPartners] = useState([]);
  const dispatch = useDispatch();

  const fetchPartners = async () => {
    const partners = ['Mon Chasseur Immo', 'pretto', 'hemea'];

    try {
      const responses = await Promise.all(
        partners.map(async (slug) => {
          try {
            const response = await PARTNERS.getPartner(slug);
            return response.data;
          } catch (e) {
            console.log(`Erreur lors de la récupération du partenaire ${slug}:`, e);
            return null;
          }
        })
      );

      setPartners(responses.filter(value => ![null, ''].includes(value)));
    } catch (error) {
      console.log('Erreur globale:', error);
    }
  }

  useEffect(() => {
    dispatch(getArticles({ per_page: 3, 'q[category_slug_eq]': 'guide-acheteur' }));
    fetchPartners();
  }, []);

  return (
    <div data-testid='BuyerGuide'>
      <PageTitle icon={D_Book} title="Guide de l'acheteur" />

      <LoadingLayer isReady={articles.length > 0 && partners.length > 0}>
        <FadeIn>
          <div className='flex flex-col gap-10'>
            <Articles />

            <Separator margins='my-12' />

            <Partners partners={partners} />
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default BuyerGuide;
