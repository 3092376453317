import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { starStruck } from '../../../shared/assets/emojis';
import { ga4Event } from '../../../shared/utils/ga4Event';
const classNames = require('classnames');

import { HosmanLogo, MenuItem, Emoji } from 'hosman-material';

export const DesktopMenu = ({ clientSwitch, navigation, selectedNav, handleClick, handleColor }) => {
  const { user } = useSelector((state) => state.user);

  const handleTitle = (item) => {
    if (item.subtitle) {
      return(
        <>
          <p>{item.name}</p>

          <div className='flex items-center gap-1 text-sm font-normal text-ih-blue'>
            {item.subtitle}
            <Emoji emojiSrc={starStruck} width='w-5' />
          </div>
        </>
      )
    } else return item.name
  }

  const handleInnerClick = (item) => {
    handleClick(item);
    item.gtmEventParams && ga4Event(item.gtmEventParams, { user_id: user.id });
  }

  return (
    <div className='hidden lg:flex lg:w-[330px] lg:flex-col lg:fixed lg:inset-y-0 bg-white z-50 border-r border-ih-indigo-10' data-testid='sidebar-menu'>
      <div className='flex-1 flex flex-col min-h-0 bg-hosman-gradient-1'>
        <div className='flex-1 flex flex-col gap-10 py-5 overflow-y-auto'>
          <div className='mt-2 mx-auto'>
            <Link to='/'><HosmanLogo /></Link>

            {clientSwitch && clientSwitch()}
          </div>

          <nav className='flex flex-col gap-6 2xl:gap-10 content-center'>
            {navigation.map((item) => (
              <div
                key={item.name}
                className={classNames('relative px-7 transition-all cursor-pointer after:duration-500',
                { "after:content-[''] after:absolute after:-right-0 after:top-0 after:h-full after:w-1 after:rounded-full after:bg-ih-blue" : item.name === selectedNav?.name })}
              >
                <div
                  onClick={() => handleInnerClick(item)}
                  className={`group flex items-center rounded-2xl ${handleColor(
                    item
                  )}`}
                >
                  <MenuItem
                    icon={item.icon}
                    title={handleTitle(item)}
                    iconColor={handleColor(item)}
                    textColor={handleColor(item)}
                    count={item.count}
                    disabled={item.disabled}
                  />
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DesktopMenu;

// PropTypes
DesktopMenu.propTypes = {
  clientSwitch: PropTypes.func,
  navigation: PropTypes.array,
  handleClick: PropTypes.func,
  handleColor: PropTypes.func
};
