import React from 'react';
import PropTypes from 'prop-types';
import { generateLink } from '../../../shared/utils/railsRoutes';

import Characteristics from '../../../shared/components/Property/Characteristics';

import { Label, Avatar, Carousel } from 'hosman-material';

const PropertySummary = ({ property, link, stateLabel, cityLabel, avatar, propertyInfos = true, premium = false, children }) => {
  const handleClick = () => {
    if (link && !property.advert?.draft) {
      window.open(link)
    }
  }

  return (
    <div onClick={() => handleClick()} className={`flex flex-col lg:flex-row gap-5 border hover:border-ih-purple-30 rounded-3xl shadow-ih-1 w-full md:w-4/5 ${!property.advert?.draft && 'cursor-pointer'}`} data-testid='PropertySummary'>
      <div className='relative w-full'>
        <Carousel images={property.property_pictures.map(picture => picture.photo_url)} />

        <div className='absolute top-4 z-10 left-4 flex gap-1'>
          <div className='flex flex-wrap gap-3'>
            <div>{stateLabel}</div>
            <div>{cityLabel}</div>
          </div>
        </div>

        {premium &&
          <div className='absolute top-4 z-10 right-8'>
            <Label color='bg-ih-yellow-70'><span className='text-ih-indigo'>Premium</span></Label>
          </div>
        }

        <div className='absolute bottom-7 left-5 lg:top-8 lg:-right-6 lg:left-[inherit] z-10 h-0'>
          {avatar && <Avatar border imageSrc={avatar} />}
        </div>
      </div>

      <div className='flex flex-col justify-between p-5 text-ih-neutral w-full'>
        {propertyInfos &&
          <div>
            <p className='h4'>{property.advert?.title}</p>
            <div className='mt-6'>
              <Characteristics property={property} />
            </div>
          </div>
        }

        <div className='mt-6 md:flex justify-between items-center flex-wrap gap-2'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PropertySummary;

// PropTypes
PropertySummary.propTypes = {
  property: PropTypes.object.isRequired,
  link: PropTypes.string,
  stateLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  avatar: PropTypes.string,
  propertyInfos: PropTypes.bool,
  premium: PropTypes.bool,
  children: PropTypes.node
};
