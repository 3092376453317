import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bell } from '../../../../../../../../../../shared/assets/emojis'

import ContactDetailsForm from '../../../../../../../../../components/BuyerAlertForms/ContactDetailsForm'
import SignInSignUpModal from '../components/SignInSignUpModal';

import {
  createBuyerAlert,
  updateStoreBuyerAlert,
  updateStoreUser,
  updateErrors,
  updateShowContactDetailsForm,
  getSimilarPropertiesCount
} from '../../../../../../../../../reducers/_Pages/Public/buyerAlertCreatorSlice';

import { Button, Emoji } from 'hosman-material';
import { S_Bell, S_ArrowLeft, S_CircleCheck } from 'hosman-material';

const ContactDetailsStep = ({ rightPanelRef }) => {
  const { user } = useSelector((state) => state.user);
  const {
    storeBuyerAlert,
    storeUser,
    similarPropertiesCount,
    errors,
    isSubmitting
  } = useSelector((state) => state.buyerAlertCreator);
  const dispatch = useDispatch();

  useEffect(() => {
    rightPanelRef.current.scrollTo({ top: 0 })

    dispatch(getSimilarPropertiesCount(storeBuyerAlert));
  }, []);

  useEffect(() => {
    if (!user.id) return;

    dispatch(updateStoreUser({
      civility: user.civility,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      phone_country_code: user.phone_country_code,
      last_sign_in_at: user.last_sign_in_at
    }));
  }, [user]);

  const handleSetStateBuyerAlert = (buyerAlert) => {
    dispatch(updateStoreBuyerAlert(buyerAlert));
    handleErrors(buyerAlert);
  }

  const handleSetStateBuyerAlertUser = (buyerAlertUser) => {
    dispatch(updateStoreUser(buyerAlertUser));
    handleErrors(buyerAlertUser);
  }

  const handleErrors = (object) => {
    Object.keys(object).forEach((key) => {
      if (errors[key]) dispatch(updateErrors({ ...errors, [key]: null }));
    });
  }

  return (
    <div className='h-full overflow-x-hidden'>
      <div className='flex flex-col gap-7'>
        {similarPropertiesCount > 0 && (
          <div className='flex justify-center items-center gap-2 p-3 border rounded-2xl bg-ih-green-5'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' size='xl' />

              <p className='font-medium'>Avec ces critères, vous recevrez en moyenne {similarPropertiesCount} biens par semaine !</p>

            <Emoji emojiSrc={bell} width='w-6' />
          </div>
        )}

        <ContactDetailsForm
          buyerAlert={storeBuyerAlert}
          setStateBuyerAlert={handleSetStateBuyerAlert}
          buyerAlertUser={storeUser}
          setStateBuyerAlertUser={handleSetStateBuyerAlertUser}
          errors={errors}
        />
      </div>

      <div className='flex justify-center gap-6 py-12'>
        <Button label='Précédent' type='text-indigo' onClick={() => dispatch(updateShowContactDetailsForm(false))} icon={S_ArrowLeft} />
        <Button label='Créer mon alerte achat'
                onClick={() => dispatch(createBuyerAlert({ user: storeUser, buyerAlertParams: storeBuyerAlert, loggedIn: user.email !== '' }))}
                icon={S_Bell}
                isLoading={isSubmitting} />
      </div>

      <SignInSignUpModal />
    </div>
  )
};

export default ContactDetailsStep;
