import React from 'react';

import { SelectInput } from 'hosman-material';

const options = [
  { id: 1, value: 'main_residence', name: 'Résidence principale' },
  { id: 2, value: 'secondary_residence', name: 'Résidence secondaire' },
  { id: 3, value: 'rental_investment', name: 'Investissement locatif' },
  { id: 4, value: 'unknown', name: 'Inconnu' },
];

const PurchaseTypeSelect = ({ value, onChange, errors }) => {
  return (
    <div className='w-full'>
      <SelectInput
        name='purchase_type'
        options={options}
        selectedOption={options.find((option) => option.value === value) || null}
        handleChange={onChange}
        label="Quel type d'achat ?"
        errors={errors}
      />
    </div>
  );
};

export default PurchaseTypeSelect;
